import { requestPost } from "@/request/http/http";
import { ActivityApi, RouletteApi, ChallengeApi } from "@/request/http";
import { store } from "@/store";
import { ActivityInfo } from "@/interface";
import { getDeviceId } from "@/utils/util";
import hallLogic from "./hallLogic";

const activityLogic = {
  getSpinInfo: () => {
    return new Promise<any>((resolve) => {
      requestPost(
        RouletteApi.GetHome,
        null,
        (response) => {
          resolve(response);
        },
        () => {
          resolve(null);
        }
      );
    });
  },
  getFirstRechargeInfo: () => {
    return new Promise<any>((resolve) => {
      requestPost(
        ActivityApi.getActivityDiscountStatus,
        null,
        (response) => {
          const temp = response?.firstRechargeShowInActivity;
          if (temp?.status) {
            resolve(temp);
          } else {
            resolve(null);
          }
        },
        () => {
          resolve(null);
        }
      );
    });
  },
  // getActivityCategory: (loading: boolean) => {
  //   requestPost(
  //     ActivityApi.ActivityCategory,
  //     {},
  //     (response) => {
  //       const res = response as any[] | null;
  //       if (res && res.length > 0) {
  //         res.forEach((item: any) => {
  //           item.text = `activity.ACTIVITY_LEFTTAB_${item.id}`;
  //         });
  //         store.commit("activities/saveleftActList", {
  //           list: res.filter((v: any) => v.id != "7" && v.id != "8"),
  //         });
  //       }
  //     },
  //     null,
  //     null,
  //     loading
  //   );
  // },
  getActivityList: (category: string) => {
    return new Promise<any>((resolve) => {
      requestPost(
        ActivityApi.ActivityList,
        { categoryType: category },
        (response) => {
          if (response?.info) {
            store.commit("activities/saveActivityList", {
              list: response.info,
              all: response.allActivity,
            });
          }

          resolve(response);
        }
      );
    });
  },
  getActivityInfo: (
    category: number,
    id: number,
    callback: (res: ActivityInfo[]) => void
  ) => {
    requestPost(
      ActivityApi.GetActivityInfo,
      { categoryType: category, id: id },
      (response) => {
        if (response) {
          const list = response as ActivityInfo[];
          callback(list);
        }
      }
    );
  },
  drawAllActivityAward: async (
    activityIds: string,
    secretIds: string | null,
    callback: (res: number) => void
  ) => {
    const params = {
      activityIds: activityIds,
      secretIds: secretIds ?? "",
      uuid: await getDeviceId(),
    };

    requestPost(
      ActivityApi.ClaimedAll,
      params,
      (response) => {
        if (response) {
          callback(response.count);
        }
      },
      null,
      null,
      true
    );
  },
  // drawActivityAward: async (activityIds: string, callback: (res: number) => void) => {
  //   requestPost(
  //     ActivityApi.ClaimedByRecordId,
  //     { activityIds: activityIds, uuid: await getDeviceId() },
  //     (response) => {
  //       if (response) {
  //         callback(response.count);
  //       }
  //     }
  //   );
  // },
  // 获取活跃度宝箱列表
  getLivelinessInfo: () => {
    requestPost(
      ChallengeApi.GetLivelinessInfo,
      null,
      (response) => {
        const res = response as any | null;
        if (res == null) return;
        if (res?.list && res?.list.length > 0) {
          res.list.unshift({
            target: "0",
          });

          store.commit("activities/saveAtivoBoxInfo", { data: res });
        }
      },
      () => {
        store.commit("activities/saveAtivoBoxInfo", { data: null });
      }
    );
  },
  // 获取任务左侧分类列表
  getTaskActList: (loading?: boolean) => {
    requestPost(
      ChallengeApi.GetChallengeCategory,
      null,
      (response) => {
        if (!response) return;

        const newTaskTabs: any[] = [];
        response.list?.forEach((item: any) => {
          if (item.id != "6") {
            newTaskTabs.push({
              taskType: item.id,
              taskName: item.name,
            });
          }
        });
        store.commit("activities/saveTaskActList", {
          list: newTaskTabs,
          all: response.allChallenge,
        });
        newTaskTabs.forEach((v) => {
          if (v.taskType == "5") {
            if (store.getters.loginSuccess && !store.getters.userType.isGuest) {
              hallLogic.getCheckList();
              activityLogic.getDailyCheckInStatus();
            }
          } else {
            activityLogic.getChallengeListByCategory(v.taskType);
          }
        });
      },
      null,
      null,
      loading
    );
  },
  getChallengeListByCategory: (challengeType: string) => {
    if (challengeType == "5" || challengeType == "6") return;

    const params = {
      challengeType,
      brandType: 2,
    };
    requestPost(ChallengeApi.GetChallengeListByCategory, params, (response) => {
      let canClaim = false;
      if (response.list && response.list.length > 0) {
        // 排序规则 可领取1>未完成0>已领取2  三种类型的任务排序好后以目标金额从大到小排序
        const taskArr0 = response.list.filter((v: any) => v.claimed == "0");
        const taskArr1 = response.list.filter((v: any) => v.claimed == "1");
        const taskArr2 = response.list.filter((v: any) => v.claimed == "2");
        response.list = [...taskArr1, ...taskArr0, ...taskArr2];
        canClaim = taskArr1.length > 0;
      }

      store.commit("activities/saveTaskData", {
        key: challengeType,
        res: response,
      });
      store.commit("activities/saveTaskRedDot", {
        key: challengeType,
        res: canClaim,
      });
    });
  },
  getActivityInfoSecretBonus: async (showLoading?: boolean) => {
    const params = {
      uuid: await getDeviceId(),
    };

    return new Promise<any>((resolve) => {
      requestPost(
        ActivityApi.GetActivityInfoSecretBonus,
        params,
        (response) => {
          const info = response?.info;
          const canClaim = info
            ? Object.values(info).some((value: any) => value.claimStatus == "1")
            : false;
          store.commit("activities/saveRedDot", {
            key: "0-0-0-13",
            res: canClaim,
          });
          store.commit(
            "activities/saveClaimSecretIds",
            response?.basic?.claimSecretIds
          );
          resolve(response);
        },
        null,
        null,
        showLoading
      );
    });
  },
  getDailyCheckInStatus: () => {
    requestPost(ChallengeApi.isAlreadyCheckIn, null, (response) => {
      store.commit("activities/saveDailyCheckInStatus", response.checkInStatus);
    });
  },
};
export default activityLogic;
